import { Button, Modal } from "antd";
import { DocumentTypeSelect } from "components/common/DocumentTypeSelect";
import { Filelist } from "components/ManualUpload/Filelist";
import { BUILT_IN_DOCUMENT_TYPES, HasDocTypeFieldsWithName } from "components/documents/documentUtils";
import React, { useState } from "react";
import pluralize from "pluralize";
import { useRouter } from "next/router";
import { DocumentType } from "__generated__/globalTypes";

interface UploadConfirmModalProps {
  visible: boolean;
  onClose: VoidFunction;
  onOK: (documentType: HasDocTypeFieldsWithName) => void;
  children?: React.ReactNode;
  files: Nullable<File[]>;
}

/**
 *  Modal that appear after dropping files
 */

export const UploadConfirmModal = (props: UploadConfirmModalProps) => {
  const router = useRouter();
  const [documentType, setDocumentType] = useState<Optional<HasDocTypeFieldsWithName>>();

  // Get a default value for the type, just default types for now to prevents too many bugs
  // @TODO handle custom types for default value also
  if (!documentType && router.query["dt"] && !router.query["cdtID"]) {
    const dt = router.query["dt"] as unknown as DocumentType;
    const newDocumentType: HasDocTypeFieldsWithName = {
      name: pluralize(BUILT_IN_DOCUMENT_TYPES[dt].name, 1),
      documentType: dt,
      customTypeId: null,
    };
    setDocumentType(newDocumentType);
  }

  return (
    <Modal
      visible={props.visible}
      title="Import Documents"
      destroyOnClose={true}
      zIndex={150}
      onCancel={props.onClose}
      footer={false}
      width={700}
    >
      <h3>What type of documents are these?</h3>
      <DocumentTypeSelect
        onChange={(val) => {
          if (val) setDocumentType(val);
        }}
        selected={documentType}
      />
      <Filelist files={props.files} />
      <br />
      <Button type="primary" disabled={!documentType} onClick={() => props.onOK(documentType!)} style={{ float: "right" }}>
        Import {props.files?.length || ""} {pluralize(documentType?.name || "Document", props.files?.length)}
      </Button>
      <Button type="default" onClick={props.onClose} style={{ float: "left" }}>
        Cancel
      </Button>
      <br style={{ clear: "both" }} />
      {props.children}
    </Modal>
  );
};
